<template>
  <div
    class="imgs"
    @click="onMouseOver"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <Questionmark
      v-if="canvasFocused"
      :tooltip="$t('questionmark.image_field_filled')"
      btn-class="btn--info"
    />
    <BaseButtonIcon
      v-if="isMobile && owner && !editEnabled"
      size="small"
      class="btn--edit"
      data-testid="images-editor-edit-button"
      @click="enableEdition"
    >
      <Icon>{{ svgPencil }}</Icon>
    </BaseButtonIcon>
    <div
      id="fabric-canvas-wrapper"
      v-resize="resizeCanvasMobile"
      v-resize-element="resizeCanvasDesktop"
      :class="{ 'none-pointer-events': isMobile && !editEnabled }"
    >
      <CanvasMobileNavigation
        v-if="canvasFocused"
        class="canvas-navigation"
        @zoomIn="canvasZoomIn"
        @zoomOut="canvasZoomOut"
        @reset="resetCanvasViewport"
      >
        <BaseButtonIcon @click="resetCanvasViewport">
          <Icon>{{ svgFullscreen }}</Icon>
        </BaseButtonIcon>
        <BaseButtonIcon @click="canvasZoomIn">
          <Icon>{{ svgPlus }}</Icon>
        </BaseButtonIcon>
        <BaseButtonIcon @click="canvasZoomOut">
          <Icon>{{ svgMinus }}</Icon>
        </BaseButtonIcon>
      </CanvasMobileNavigation>
      <canvas id="fabric-canvas"></canvas>
    </div>
    <ImageTooltip
      v-show="owner && (editEnabled || !isMobile)"
      ref="imageTooltip"
      :selected-image="selectedImage"
      @front="handleFlipToFront"
      @back="handleFlipToBack"
      @delete="handleDelete"
      @filters="toggleImageFilters"
      @filterBuilder="toggleFilterBuilder"
    >
      <FiltersForm
        :visible="filtersModal"
        :selected-image="selectedImage"
        @changeImageFilter="changeImageFilter"
      />
      <FilterBuilderForm
        :visible="filterBuilderModal"
        @applyFilterPreviewValue="applyFilterPreviewValue"
        @createFilterPreview="createFilterPreview"
        @changeImageFilter="changeImageFilter"
        @filterCreated="onFilterCreated"
        @onFilterBuilderClose="onFilterBuilderClose"
      />
    </ImageTooltip>
    <AddImagesSection
      v-if="owner && (editEnabled || !isMobile)"
      :canvas-focused="canvasFocused"
      :images-count="block.images.length"
      :instagram-access-token="instagramAccessToken"
      @picturesImport="picturesImport"
      @afterComplete="afterComplete"
      @modalHandler="modalHandler"
    />
  </div>
</template>

<script>
import { fabric } from "fabric";
import { mapActions, mapState } from "vuex";
import {
  CanvasZoomBoundaries,
  imageControlsVisibilityOptions,
  ImageSizeMaxBoundaries,
  imageStaticOptions
} from "@/options/imageOptions";
import { initialFiltersConfig } from "@/options/filterOptions";
import {
  createFabricCanvas,
  disableCanvasEdition,
  enableCanvasEdition
} from "@/helpers/canvasHelpers";
import {
  canvasOptions,
  defaultViewportTransform,
  defaultZoom
} from "@/options/canvasOptions";
import {
  disableImageEdition,
  enableImageEdition,
  getFileLengthInBytes,
  getImageScalingOptions,
  resizeImage
} from "@/helpers/imageHelpers";
import { uuid } from "vue-uuid";
import { applyImageFilter } from "@/helpers/filterHelpers";
import userMixin from "@/mixins/user";
import AddImagesSection from "@/components/Images/ImagesAddSection";
import mixins from "vue-typed-mixins";
import { mdiFullscreen, mdiMinus, mdiPencil, mdiPlus } from "@mdi/js";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { notificationStatus } from "@/options/notificationOptions";
import resize from "vue-resize-directive";

export default mixins(userMixin).extend({
  name: "ImagesEditor",
  components: {
    AddImagesSection,
    ImageTooltip: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Images/ImageTooltip.vue"
      ),
    FilterBuilderForm: () =>
      import("@/components/Forms/Images/ImageCreateFilterForm"),
    FiltersForm: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Images/ImageFilterForm"
      ),
    CanvasMobileNavigation: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Images/ImagesFieldMobileNav"
      )
  },
  directives: {
    "resize-element": resize
  },
  mixins: [userMixin],
  props: {
    block: {
      type: Object,
      required: true
    },
    canvasFocused: {
      type: Boolean,
      required: true
    },
    instagramAccessToken: {
      type: String,
      required: false
    },
    attrInstagramModal: {
      type: Boolean,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      mouseOver: false,
      editEnabled: false,
      svgPencil: mdiPencil,
      selectedImage: {},
      objMovingEndTimeout: 4000,
      imagesModal: false,
      filtersModal: false,
      filterBuilderModal: false,
      imageTooltip: false,
      imagesData: this.block.images,
      canvas: null,
      svgFullscreen: mdiFullscreen,
      svgPlus: mdiPlus,
      svgMinus: mdiMinus
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard", "boards"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    "block.images": {
      handler: function(newVal) {
        if (this.canvas) {
          this.canvas.objectCaching = false;
          if (this.block.images.length === 0) {
            this.canvas.clear();
          } else if (this.imagesData.length < this.block.images.length) {
            const newImages = this.block.images.filter(
              el => !this.imagesData.find(a => el.imageId === a.imageId)
            );
            this.mapImages(newImages);
            setTimeout(() => {
              if (!this.canvasFocused) this.clickCanvas();
            }, 0);
          } else {
            this.externalFabricChangesWatcher(newVal);
          }

          this.imagesData = [...this.block.images];
        }
      },
      deep: true
    },
    "block.images.length"(newVal, oldVal) {
      if (newVal === 1) {
        analyticsLogEvent(gaEventNames.first_image_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user
        });
      }
      if (newVal && newVal >= 1) {
        analyticsLogEvent(gaEventNames.image_block_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user
        });
      }
      if (newVal && this.boards.length === 1) {
        if (newVal >= 3 && oldVal < 3) {
          analyticsLogEvent(gaEventNames.images_field_3_added, {
            board_id: this.currentBoard.id,
            board_user_id: this.currentBoard.user,
            board_images_count: newVal
          });
        }
        if (newVal >= 4 && oldVal < 4) {
          analyticsLogEvent(gaEventNames.images_field_4_added, {
            board_id: this.currentBoard.id,
            board_user_id: this.currentBoard.user,
            board_images_count: newVal
          });
        }
      }
    },
    imageTooltip: function() {
      if (!this.imageTooltip) {
        this.filtersModal = false;
      }
    },
    canvasFocused(val) {
      if (!val) {
        if (!this.imagesModal) {
          this.disableEdition();
        }
        this.hideMenuTooltip();
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        this._saveBoard({ thumbnail: this.canvas.toDataURL() });
      }
    },
    imagesModal(val) {
      if (!val) {
        this.disableEdition();
      }
    }
  },
  async mounted() {
    await this.isOwner(this.currentBoard?.id);
    this.canvas = createFabricCanvas(
      "fabric-canvas",
      this.$vuetify.breakpoint.smAndDown
        ? canvasOptions
        : {
            ...canvasOptions,
            ...this.options
          }
    );

    if (!this.isMobile && this.owner) {
      this.attachCanvasListeners();
    } else {
      disableCanvasEdition(this.canvas);
    }
    this.resizeCanvas();

    const { viewportTransform, zoom } = this.block;
    this.canvas.setZoom(zoom);
    this.canvas.setViewportTransform(viewportTransform);
    this.imagesData = [...this.block.images];
    this.mapImages(this.block.images);

    setTimeout(() => {
      this.openShareModal();
    }, 4000);
    if (window.Cypress) {
      window.ImagesCanvas = this.canvas;
    }
  },
  methods: {
    ...mapActions("boards", ["saveBoard"]),
    ...mapActions("modals", ["openShareModal"]),
    externalFabricChangesWatcher(newVal) {
      this.canvas.getObjects().map(image => {
        const index = newVal.findIndex(elem => elem.imageId === image.imageId);
        const element = newVal[index];
        if (element) {
          if (!image.isMoving) {
            image.set({
              left: element.left,
              top: element.top,
              angle: element.angle,
              scaleX: element.width / image.width,
              scaleY: element.height / image.height
            });
            image.setCoords();
            image.moveTo(index);
          }
        }
      });
      this.canvas.renderAll();
    },
    modalHandler(val) {
      this.imagesModal = val;
    },
    onMouseOver() {
      this.mouseOver = true;
    },
    onMouseLeave() {
      this.mouseOver = false;
    },
    enableEdition() {
      this.clickCanvas();
      this.editEnabled = true;
      this.canvas.allowTouchScrolling = false;
      if (this.owner) {
        enableCanvasEdition(this.canvas);
        this.attachCanvasListeners();
        const images = this.canvas.getObjects();
        if (images.length) {
          images.forEach(async (img, id) => {
            await img.setControlsVisibility(imageControlsVisibilityOptions);
            enableImageEdition(img);
            if (id === images.length - 1) {
              this.selectedImage = img;
              this.canvas.setActiveObject(img);
              this.canvas.renderAll();
              this.setMenuTooltipPosition(img);
              this.showMenuTooltip();
            }
          });
        }
      }
    },
    disableEdition() {
      if (!this.isMobile) return;
      this.editEnabled = false;
      disableCanvasEdition(this.canvas);
      this.canvas.allowTouchScrolling = true;
      this.detachCanvasListeners();
      const images = this.canvas.getObjects();
      images.forEach(img => {
        disableImageEdition(img);
      });
      this.hideMenuTooltip();
    },
    clickCanvas() {
      this.$emit("clicked");
    },
    isInitialCanvasZoom() {
      return this.canvas?.getZoom() === 1;
    },
    canvasZoomIn() {
      this.canvas.setZoom(this.canvas.getZoom() + 0.2);
    },
    canvasZoomOut() {
      this.canvas.setZoom(this.canvas.getZoom() - 0.2);
    },
    resetCanvasViewport() {
      if (this.canvas.viewportTransform !== defaultViewportTransform) {
        this.canvas.setViewportTransform(defaultViewportTransform);
        this.handleViewportUpdate(defaultViewportTransform);
      }

      if (this.canvas.getZoom() !== defaultZoom) {
        this.canvas.setZoom(defaultZoom);
        this.handleZoomUpdate(defaultZoom);
      }
    },
    resizeCanvasMobile() {
      if (this.isMobile) this.resizeCanvas();
    },
    resizeCanvasDesktop() {
      if (!this.isMobile) this.resizeCanvas();
    },
    resizeCanvas() {
      this.hideMenuTooltip();
      const outerCanvasContainer = document.getElementById(
        "fabric-canvas-wrapper"
      );

      let canvasHeight;
      if (this.isMobile) {
        canvasHeight =
          window.screen.height > window.screen.width
            ? window.screen.height - 325
            : window.screen.height;
      } else {
        const containerHeight = outerCanvasContainer?.clientHeight - 50;
        canvasHeight =
          containerHeight < this.options?.height
            ? this.options?.height
            : containerHeight;
      }

      if (this.canvas) {
        const containerWidth = outerCanvasContainer?.clientWidth - 6;
        const scale = containerWidth / this.canvas.getWidth();
        const zoom = this.canvas.getZoom() * scale;

        this.canvas.setDimensions({
          width: containerWidth,
          height: canvasHeight
        });

        this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
        this.handleViewportUpdate([zoom, 0, 0, zoom, 0, 0]);
        this.handleZoomUpdate(zoom);
      }
    },

    async afterComplete(images) {
      const nextImages = [];
      let position = 0;
      for (const pic of images) {
        const id = images.indexOf(pic);
        const { dataURL, name, width, height } = pic;
        const imageFileSize = getFileLengthInBytes(dataURL);
        const fabricCanvas = this.canvas;
        let imageBuffer = dataURL;
        if (
          imageFileSize > ImageSizeMaxBoundaries.MEDIUM ||
          imageFileSize > ImageSizeMaxBoundaries.SMALL
        ) {
          const scalingOptions = getImageScalingOptions(imageFileSize);
          const resizeWidth = width * scalingOptions.factor;
          const resizeHeight = (resizeWidth * height) / width;

          imageBuffer = await resizeImage(dataURL, resizeWidth, resizeHeight);
        }

        const nextWidth = fabricCanvas.getWidth() / 3;
        const nextHeight = (nextWidth * height) / width;
        const nextImage = {
          name,
          imageId: uuid.v4(),
          src: String(imageBuffer),
          angle: 360,
          width: parseInt(String(nextWidth)),
          height: parseInt(String(nextHeight)),
          left: position + nextWidth / 2,
          top: nextHeight / 2
        };

        nextImages.push(nextImage);
        if ((id + 1) % 4 === 0) {
          position = 0;
        } else {
          position = position + nextWidth;
        }
      }

      this.block.images = this.block.images.concat(nextImages);
      analyticsLogEvent(gaEventNames.board_images_added, {
        board_id: this.currentBoard.id,
        board_user_id: this.currentBoard.user,
        board_images_count: nextImages.length,
        board_images_upload_source: "local_drive"
      });
      await this._saveBoard();
    },
    getNextImagePosition(block) {
      const canvas = this.canvas;
      const divisionRest = block.images.length % 4;
      if (divisionRest === 0) return 0;

      const width = canvas.getWidth() / 3;
      return parseInt(String(width * divisionRest));
    },
    async picturesImport(pics, source) {
      const nextPictures = [];
      async function loadImage(imageUrl) {
        let img;
        const imageLoadPromise = new Promise(resolve => {
          img = new Image();
          img.onload = resolve;
          img.src = imageUrl;
        });

        await imageLoadPromise;
        return img;
      }

      for (const pic of pics) {
        const blob = await fetch(pic).then(r => r.blob());
        const dataUrl = await new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = () => {
            return resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });

        const { getNextImagePosition, snackbarText, notification } = this;

        const img = await loadImage(String(dataUrl));
        const width = img?.width;
        const height = img?.height;
        const imageFileSize = getFileLengthInBytes(String(dataUrl));
        if (imageFileSize > ImageSizeMaxBoundaries.LARGE) {
          notification({
            ...notificationStatus.ERROR,
            message: snackbarText
          });
        } else {
          let imageBuffer = dataUrl;
          if (
            imageFileSize > ImageSizeMaxBoundaries.MEDIUM ||
            imageFileSize > ImageSizeMaxBoundaries.SMALL
          ) {
            const scalingOptions = getImageScalingOptions(imageFileSize);
            const resizeWidth = width * scalingOptions.factor;
            const resizeHeight = (resizeWidth * height) / width;

            imageBuffer = await resizeImage(
              String(dataUrl),
              resizeWidth,
              resizeHeight
            );
          }

          const fabricCanvas = this.canvas;
          const nextWidth = fabricCanvas.getWidth() / 3;
          const nextHeight = (nextWidth * height) / width;
          const nextImage = {
            // should we generate something?
            name: "instagram_image",
            imageId: uuid.v4(),
            src: String(imageBuffer),
            angle: 360,
            width: parseInt(String(nextWidth)),
            height: parseInt(String(nextHeight)),
            left: getNextImagePosition(this.block) + nextWidth / 2,
            top: nextHeight / 2
          };
          nextPictures.push(nextImage);
        }
      }

      this.block.images = this.block.images.concat(nextPictures);
      await this._saveBoard();
    },
    handleZoomUpdate(next) {
      this.$emit("onZoomUpdate", this.block, next);
    },
    handleViewportUpdate(next) {
      this.$emit("onViewportUpdate", this.block, next);
    },
    handleDelete() {
      const image = this.canvas.getActiveObject();
      const { imageId } = image;
      this.canvas.remove(image);
      analyticsLogEvent(gaEventNames.board_image_removed, {
        board_id: this.currentBoard.id,
        board_user_id: this.currentBoard.user
      });
      const imageKey = this.block.images.findIndex(
        el => el.imageId === imageId
      );
      this.$emit("onDelete", imageKey);
    },
    handleScale(img, res, imageId) {
      const imageKey = this.block.images.findIndex(
        el => el.imageId === imageId
      );
      this.$emit("onImageScale", img, res, imageKey);
    },
    handleFlipToFront() {
      const image = this.canvas.getActiveObjects()[0];
      const { imageId } = image;
      image.bringToFront();
      this.$emit(
        "onFlipToFront",
        this.block.images.find(el => el.imageId === imageId)
      );
    },
    setMenuTooltipPosition(obj) {
      const absCoords = this.canvas.getAbsoluteCoords(obj);
      const outerCanvasContainer = document.getElementById(
        "fabric-canvas-wrapper"
      );
      const containerWidth = outerCanvasContainer?.clientWidth;
      const scale = containerWidth / this.canvas.getWidth();
      const zoom = this.canvas.getZoom() * scale;
      const canvasWidth = this.canvas.width;
      const menuWidth = 250;

      const vpt = this.canvas.viewportTransform;

      let nextLeft = absCoords.left * zoom - menuWidth / 2 + vpt[4];
      const nextTop = absCoords.top * zoom + absCoords.height / 2 + 30 + vpt[5];

      if (nextLeft <= 0) {
        nextLeft = 0;
      } else if (nextLeft + menuWidth >= canvasWidth) {
        nextLeft = canvasWidth - menuWidth;
      }
      if (this.$refs.imageTooltip) {
        this.$refs.imageTooltip.$el.style.left = `${nextLeft}px`;
        this.$refs.imageTooltip.$el.style.top = `${nextTop}px`;
      }
    },
    showMenuTooltip() {
      if (this.$refs.imageTooltip) {
        this.imageTooltip = true;
        this.$refs.imageTooltip.$el.style.visibility = "visible";
        this.$refs.imageTooltip.$el.classList.add("selected");
        this.$refs.imageTooltip.$el.style.opacity = 1;
      }
    },
    hideMenuTooltip() {
      if (this.$refs.imageTooltip) {
        this.imageTooltip = false;
        this.$refs.imageTooltip.$el.style.visibility = "hidden";
        this.$refs.imageTooltip.$el.classList.remove("selected");
        this.$refs.imageTooltip.$el.style.opacity = 0;
      }
    },
    handleFlipToBack() {
      const image = this.canvas.getActiveObjects()[0];
      const { imageId } = image;
      image.sendToBack();
      this.$emit(
        "onFlipToBack",
        this.block.images.find(el => el.imageId === imageId)
      );
    },
    createImage(image) {
      const { canvas, setMenuTooltipPosition } = this;
      fabric.Image.fromURL(
        image.src,
        img => {
          canvas.add(
            img.set({
              name: image.name,
              left: image.left,
              top: image.top,
              angle: image.angle,
              imageId: image.imageId,
              ...imageStaticOptions
            })
          );
          img.set("scaleX", image.width / img.width);
          img.set("scaleY", image.height / img.height);
          if ("filters" in image) {
            const { filters } = image;
            const nextFilters = [];

            filters.subFilters.map(el => {
              const obj = { ...el };
              nextFilters.push(
                new fabric.Image.filters[el.type]({
                  ...obj
                })
              );
            });

            img.filters = nextFilters;
            img.filterName = filters.filterName;
            img.filterKey = filters.filterKey;
            img.applyFilters();
          }
          if (this.owner) {
            if (!this.isMobile) {
              img.setControlsVisibility(imageControlsVisibilityOptions);
            }
            this.attachImageListeners(img);
            setMenuTooltipPosition(img);
          }
          if (!this.owner || (!this.editEnabled && this.isMobile)) {
            disableImageEdition(img);
          }
        },
        { crossOrigin: "anonymous" }
      );
    },
    setObjectTweaking() {
      this.hideMenuTooltip();
    },
    attachImageListeners(img) {
      img.on("moving", this.setObjectTweaking);
      img.on("scaling", this.setObjectTweaking);
      img.on("rotating", this.setObjectTweaking);

      img.on("modified", e => {
        const nextTop = e.target.top;
        const nextLeft = e.target.left;
        const nextDimensions = {
          height: e.target.getScaledHeight(),
          width: e.target.getScaledWidth(),
          angle: e.target.angle,
          left: nextLeft,
          top: nextTop
        };
        this.handleScale(img, nextDimensions, img.imageId);
      });

      img.on("selected", () => {
        this.selectedImage = { ...img };
        if (this.canvas.getActiveObjects().length <= 1) {
          this.showMenuTooltip();
        }
      });

      const checkIfObjectDeleted = e => Object.keys(e).length <= 0;
      const checkIfClickedOnMenu = e => e.e === undefined;

      img.on("deselected", e => {
        if (checkIfObjectDeleted(e)) {
          // e keys empty, there's object has been deleted
          this.hideMenuTooltip();
        } else if (
          checkIfClickedOnMenu(e) &&
          this.canvas.getActiveObjects().length <= 0 &&
          this.canvasFocused
        ) {
          this.canvas.setActiveObject(img);
        } else {
          this.hideMenuTooltip();
        }
      });
    },
    toggleFilterBuilder() {
      if (this.filtersModal) this.filtersModal = false;
      this.filterBuilderModal = !this.filterBuilderModal;
    },
    toggleImageFilters() {
      if (this.filterBuilderModal) this.filterBuilderModal = false;
      this.filtersModal = !this.filtersModal;
    },
    createFilterPreview(index, filter) {
      const image = this.canvas.getActiveObject();
      image.filters[index] = filter;
      image.applyFilters();
      this.canvas.renderAll();
    },
    applyFilterPreviewValue(index, prop, value) {
      const obj = this.canvas.getActiveObject();
      if (obj?.filters[index]) {
        obj.filters[index][prop] = value;
        obj.applyFilters();
        this.canvas.renderAll();
      }
    },
    onFilterCreated() {
      this.filterBuilderModal = false;
      this.filtersModal = true;
    },
    onFilterBuilderClose() {
      const filter = applyImageFilter(
        initialFiltersConfig.find(
          el => el.key === this.selectedImage.filterKey || "default"
        )
      );
      if (filter) {
        this.changeImageFilter(filter);
      }
    },
    changeImageFilter(composed) {
      const obj = this.canvas.getActiveObject();
      const image = this.block.images.find(el => el.imageId === obj.imageId);
      obj.filters = [composed];
      this.selectedImage = obj;
      this.selectedImage.filterName = composed.filterName;
      this.selectedImage.filterKey = composed.filterKey;

      this.$emit("onFilterUpdate", image, composed);
      obj.applyFilters();
      this.canvas.renderAll();
    },
    async _saveBoard() {
      await this.saveBoard({ thumbnail: this.canvas.toDataURL() });
    },
    _calculateNextZoom(delta) {
      const canvasEmpty = this.canvas.getObjects().length === 0;
      let zoom = this.canvas.getZoom();
      if (!canvasEmpty && this.canvasFocused) {
        zoom *= 0.999 ** delta;
        if (zoom > CanvasZoomBoundaries.MAX) zoom = CanvasZoomBoundaries.MAX;
        if (zoom < CanvasZoomBoundaries.MIN) zoom = CanvasZoomBoundaries.MIN;
      } else {
        zoom = 1;
      }

      return zoom;
    },
    _calculateNextViewport(viewportTransform) {
      return viewportTransform.map((el, id) => {
        // is not zoom
        if (id !== 0 && id !== 3) {
          return Math.round(el);
        }
        // is zoom - don't round value
        return Number(parseFloat(el).toFixed(3));
      });
    },
    detachCanvasListeners() {
      this.canvas.__eventListeners = {};
    },
    attachCanvasListeners() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      let wheeling;

      const getFocused = () => {
        return this.canvasFocused;
      };

      this.canvas.on("selection:created", e => {
        if (e.target.type === "activeSelection") {
          vm.canvas.discardActiveObject();
        }
      });

      this.canvas.on("mouse:down", function(opt) {
        if (!getFocused()) {
          vm.clickCanvas();
        }
        const evt = opt.e;
        if (evt.altKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });

      this.canvas.on("mouse:wheel", function(opt) {
        const evt = opt.e;
        if (evt.altKey === true) {
          opt.e.preventDefault();
          opt.e.stopPropagation();

          clearTimeout(wheeling);
          const image = this.getActiveObjects()[0];
          const canvasEmpty = this.getObjects().length === 0;

          if (canvasEmpty || !getFocused() || vm.isMobile) return;
          vm.hideMenuTooltip();
          const delta = opt.e.deltaY;
          const zoom = vm._calculateNextZoom(delta);
          this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          const { viewportTransform } = this;
          const nextViewport = vm._calculateNextViewport(viewportTransform);
          this.setViewportTransform(nextViewport);

          //detect when scrolling has stopped
          wheeling = setTimeout(function() {
            vm.handleViewportUpdate(nextViewport);
            vm.handleZoomUpdate(zoom.toFixed(3));
            if (image && (vm.editEnabled || !vm.isMobile)) {
              vm.setMenuTooltipPosition(image);
              vm.showMenuTooltip();
            }
          }, 66);
        }
      });

      this.canvas.on("mouse:move", function(opt) {
        const canvasEmpty = this.getObjects().length === 0;
        if (this.isDragging && !canvasEmpty) {
          const e = opt.e;
          const vpt = this.viewportTransform;

          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });

      this.canvas.on("mouse:up", function(opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        vm.handleViewportUpdate(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
        const image = this.getActiveObject();
        if (
          image &&
          this.getActiveObjects().length <= 1 &&
          (vm.editEnabled || !vm.isMobile)
        ) {
          vm.setMenuTooltipPosition(image);
          vm.showMenuTooltip();
        }
      });
    },
    mapImages(images) {
      images.forEach(image => {
        this.createImage(image);
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.imgs {
  height: 100%;
  position: relative;
}

.hidden {
  display: none;
}

.canvas-container {
  height: 100%;
}
.none-pointer-events ::v-deep .lower-canvas,
.none-pointer-events ::v-deep .upper-canvas {
  pointer-events: none;
}

.btn--edit {
  position: absolute;
  z-index: 7;
  right: 10px;
  top: 10px;
}

::v-deep .btn--info {
  position: absolute;
  z-index: 7;
  left: 10px;
  top: 10px;
}

#fabric-canvas-wrapper {
  height: 100%;
  border-radius: 4px;
  position: relative;
  border: 1px solid transparent;
  padding: 3px !important;
}

#fabric-canvas {
  border-radius: 4px;
}
</style>

// @ts-nocheck
import { Node, Plugin } from "tiptap";
import { splitToDefaultListItem, liftListItem } from "tiptap-commands";
import { uuid } from "vue-uuid";

export default class TodoItem extends Node {
  get name() {
    return "todo_item";
  }

  get view() {
    return {
      props: ["node", "updateAttrs", "view"],
      methods: {
        onChange() {
          this.updateAttrs({
            done: !this.node.attrs.done
          });
        }
      },
      template: `
        <li :data-type="node.type.name" :data-item-id="node.attrs.itemId" :data-done="node.attrs.done.toString()" data-drag-handle>
          <span class="todo-checkbox" contenteditable="false" @click="onChange"></span>
          <div class="todo-content" ref="content" :contenteditable="view.editable.toString()"></div>
        </li>
      `,
      render(h) {
        return h(
          "li",
          {
            attrs: {
              "data-type": this.node.type.name,
              "data-done": this.node.attrs.done.toString(),
              "data-drag-handle": "",
              "data-item-id": this.node.attrs.itemId
            }
          },
          [
            h("span", {
              class: "todo-checkbox",
              attrs: {
                contenteditable: false
              },
              on: {
                click: this.onChange
              }
            }),
            h("div", {
              class: "todo-content",
              attrs: {
                contenteditable: this.view.editable.toString()
              },
              ref: "content"
            })
          ]
        );
      }
    };
  }

  get schema() {
    return {
      attrs: {
        done: {
          default: false
        },
        itemId: {
          default: null
        }
      },
      draggable: true,
      content: "paragraph+",
      toDOM: node => {
        const { done, itemId } = node.attrs;

        return [
          "li",
          {
            "data-type": this.name,
            "data-done": done.toString(),
            "data-item-id": itemId
          },
          ["span", { class: "todo-checkbox", contenteditable: "false" }],
          ["div", { class: "todo-content" }, 0]
        ];
      },
      parseDOM: [
        {
          priority: 51,
          tag: `[data-type="${this.name}"]`,
          getAttrs: dom => ({
            itemId: dom.getAttribute("data-item-id") || uuid.v4(),
            done: dom.getAttribute("data-done") === "true"
          })
        }
      ]
    };
  }

  get plugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          const newTr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (!!node.type && node.type.name === "todo_item") {
              const { itemId, ...rest } = node.attrs;

              if (itemId === undefined || itemId === null || itemId === "") {
                newTr.setNodeMarkup(pos, undefined, {
                  itemId: uuid.v4(),
                  ...rest
                });
                modified = true;
              }
            }
          });

          if (modified) {
            return newTr;
          }
        }
      })
    ];
  }

  keys({ type }) {
    return {
      Enter: splitToDefaultListItem(type),
      "Shift-Tab": liftListItem(type)
    };
  }
}

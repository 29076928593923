import { WHITE } from "@/utils/color";

export const defaultViewportTransform = [1, 0, 0, 1, 0, 0];

export const defaultZoom = 1;

export enum initialCanvasDimensionsDefault {
  WIDTH = 750,
  HEIGHT = 750
}

export const canvasOptions = {
  backgroundColor: WHITE,
  preserveObjectStacking: true,
  width: initialCanvasDimensionsDefault.WIDTH,
  height: initialCanvasDimensionsDefault.HEIGHT
};

<template>
  <div>
    <div v-if="imagesSectionVisible">
      <BaseButton text class="btn--upload" @click="showModal">
        <Icon small icon-class="pr-1">vb-upload</Icon> Upload images
      </BaseButton>
      <vue-dropzone
        v-if="imagesSectionVisible"
        id="image-upload"
        ref="imgDropZone"
        data-testid="dropzone-drag-and-drop"
        :use-custom-slot="true"
        :options="dropzoneOptions"
        @vdropzone-files-added="imagesAdded"
        @vdropzone-thumbnail="imageUploadComplete"
      >
        <p class="dropzone-message">
          or drag &amp; drop your images here
        </p>
      </vue-dropzone>
    </div>
    <BaseModal :value.sync="imagesModal" title="Load photos">
      <ImagesModal
        v-if="imagesModal"
        class="images-modal"
        @close="imagesModal = false"
        @localDriveButtonClick="localDriveButtonClick"
        @picturesImport="picturesImport"
      />
    </BaseModal>
  </div>
</template>

<script>
import { formatFileSize, getFileLengthInBytes } from "@/helpers/imageHelpers";
import { ImageSizeMaxBoundaries } from "@/options/imageOptions";
import { mapActions, mapGetters } from "vuex";
import { notificationStatus } from "@/options/notificationOptions";
import { toggleIntercomButton } from "@/plugins/intercom";

export default {
  name: "ImagesAddSection",
  components: {
    ImagesModal: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Images/ImagesAddModal.vue"
      ),
    vueDropzone: () => import(/* webpackPrefetch: true */ "vue2-dropzone")
  },
  props: {
    imagesCount: {
      type: Number,
      required: true
    },
    instagramAccessToken: {
      type: String
    },
    canvasFocused: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    imagesModal: false,
    imagesToUpload: [],
    imagesQueue: [],
    snackbarText: `Maximum image file size allowed: ${formatFileSize(
      ImageSizeMaxBoundaries.LARGE
    )}`,
    dropzoneOptions: {
      thumbnailWidth: 100,
      thumbnailHeight: 100,
      uploadMultiple: true,
      url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/postImage`,
      acceptedFiles: ".jpg, .jpeg, .png"
    }
  }),
  computed: {
    ...mapGetters("modals", ["instagramModal"]),
    imagesSectionVisible() {
      return this.canvasFocused || this.imagesCount === 0 || this.imagesModal;
    }
  },
  watch: {
    imagesModal: function(newVal) {
      this.$emit("modalHandler", newVal);
      toggleIntercomButton(newVal);
    },
    instagramModal: function(newVal) {
      if (newVal) {
        this.imagesModal = true;
      }
    }
  },
  mounted() {
    if (this.instagramModal) {
      this.imagesModal = true;
    }
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    localDriveButtonClick() {
      this.$refs.imgDropZone?.dropzone.hiddenFileInput.click();
    },
    showModal(e) {
      e.preventDefault();
      this.imagesModal = true;
    },
    imagesAdded(images) {
      const imagesQueue = [];
      Object.keys(images).forEach(key => {
        if (images[key].upload)
          imagesQueue.push({
            uuid: images[key].upload.uuid,
            completed: false
          });
      });
      this.imagesQueue = imagesQueue;
    },
    imageUploadComplete(image, url) {
      const completedImage = this.imagesQueue.findIndex(
        el => el.uuid === image.upload.uuid
      );
      if (completedImage > -1) {
        this.imagesQueue[completedImage].completed = true;
      }

      if (getFileLengthInBytes(image.dataURL) <= ImageSizeMaxBoundaries.LARGE) {
        this.imagesToUpload.push(image);
      } else {
        this.notification({
          ...notificationStatus.ERROR,
          message: this.snackbarText
        });
      }

      if (this.queueCompleted()) {
        this.imagesQueue = [];
        this.uploadFiles();
      }
    },
    queueCompleted() {
      return this.imagesQueue.filter(img => !img.completed).length === 0;
    },
    async uploadFiles() {
      await this.$emit("afterComplete", this.imagesToUpload);
      this.$refs.imgDropZone?.removeAllFiles();
      this.imagesToUpload = [];
      if (this.imagesModal) {
        this.imagesModal = false;
      }
    },
    picturesImport(pics) {
      this.$emit("picturesImport", pics);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn--upload {
  position: absolute;
  z-index: 7;
  right: 10px;
  bottom: 10px;
}

#image-upload {
  display: none;
}
</style>

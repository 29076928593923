






























import Vue from "vue";
import ImagesEditor from "@/components/Images/ImagesEditor.vue";
import BaseEditor from "@/components/Editors/BaseEditor.vue";
import { ImageConfig, ImagesBlockConfig } from "@/options/imageOptions";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";

export default Vue.extend({
  name: "ImagesTemplate",
  components: { BaseEditor, ImagesEditor },
  props: {
    block: Object,
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    canvasFoc: false
  }),
  computed: {
    ...mapState("boards", ["currentBoard"]),
    instagramAccessToken(): string {
      return this.$attrs.accessToken || "";
    }
  },
  methods: {
    ...mapActions("boards", ["saveBoard"]),
    clickCanv() {
      this.canvasFoc = true;
    },
    clickOutsideCanv() {
      this.canvasFoc = false;
    },
    handleImageDelete(key: number) {
      this.block.images.splice(key, 1);
      this.saveBoard();
      this.logAnalytics();
    },
    handleImageFlipToFront(image: ImageConfig) {
      // Replace item as Fabric reads z-index from array order
      this.block.images = this.block.images.filter(
        (item: ImageConfig) => item && image && item.imageId !== image.imageId
      );
      this.block.images.push(image);
      this.saveBoard();
      this.logAnalytics();
    },
    handleImageFlipToBack(image: ImageConfig) {
      // Replace item as Fabric reads z-index from array order
      this.block.images = this.block.images.filter(
        (item: ImageConfig) => item && image && item.imageId !== image.imageId
      );
      this.block.images.unshift(image);
      this.saveBoard();
      this.logAnalytics();
    },
    handleFilterUpdate(image: ImageConfig, composed: object) {
      image.filters = JSON.parse(JSON.stringify({ ...composed }));
      this.saveBoard();
      this.logAnalytics();
    },
    handleImagePropertiesUpdate(
      image: ImageConfig,
      next: ImageConfig,
      key: number
    ) {
      const nextImage = {
        ...this.block.images[key],
        ...next
      } as ImageConfig;
      this.block.images.splice(key, 1, nextImage);
      this.saveBoard();
      this.logAnalytics();
    },
    handleZoomUpdate(block: ImagesBlockConfig, next: number) {
      if (block.zoom !== next) {
        block.zoom = next;
        this.saveBoard();
      }
    },
    handleViewportUpdate(block: ImagesBlockConfig, next: Array<number>) {
      if (
        !block.viewportTransform.every(
          (element, index) => element === next[index]
        )
      ) {
        block.viewportTransform = next;
        this.saveBoard();
      }
    },
    logAnalytics: debounce(function(this: any) {
      if (this.currentBoard)
        analyticsLogEvent(gaEventNames.image_block_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user
        });
    }, 2000)
  }
});

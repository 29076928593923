<template>
  <v-card
    ref="editor"
    v-click-outside="outsideClick"
    :outlined="modal"
    :flat="blank"
    :style="{
      background: blank ? 'transparent' : background
    }"
    :class="{
      'editor-card': !blank,
      'editor-card--focused':
        editor.focused && editable && (editEnabled || !hasEditButton) && !blank
    }"
    :data-testid="checkboxEditor ? 'checkbox-editor' : ''"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    @click.native="focusEditor"
  >
    <Questionmark
      v-if="
        tooltip &&
          mouseOver &&
          (editEnabled || !hasEditButton) &&
          editable &&
          !blank
      "
      :tooltip="tooltip"
      :styles="{ position: 'absolute', left: '10px', top: '10px', zIndex: '7' }"
    />
    <BaseButtonIcon
      v-if="mouseOver && !editEnabled && hasEditButton && owner && editable"
      size="small"
      class="btn--edit"
      @click="enableEdition"
    >
      <Icon :tooltip="$t('board.edit_field')">{{ svgPencil }}</Icon>
    </BaseButtonIcon>
    <slot v-if="blank" name="actions"></slot>
    <slot v-if="!blank" name="progress"></slot>
    <v-btn
      v-if="
        !modal &&
          owner &&
          editable &&
          isFocusedEditor() &&
          (editEnabled || !hasEditButton)
      "
      fab
      x-small
      color="secondary"
      :class="{
        'btn--close-mobile': $vuetify.breakpoint.smAndDown
      }"
      class="white--text btn--close"
      :style="
        menuLeft
          ? `left: ${closeBtnPosition}px;`
          : `right: ${closeBtnPosition - 32}px;`
      "
      @click="closeToolbar"
    >
      <Icon small>{{ svgClose }}</Icon>
    </v-btn>
    <editor-menu-bar
      v-if="editable"
      v-slot="{
        commands,
        isActive,
        getMarkAttrs,
        getNodeAttrs,
        focused
      }"
      :editor="editor"
    >
      <div
        ref="menu"
        :class="{
          'is-active': focused && owner && (editEnabled || !hasEditButton),
          menububble: !$vuetify.breakpoint.smAndDown,
          'menububble--mobile': $vuetify.breakpoint.smAndDown
        }"
        :style="
          menuLeft ? `left: ${menuPosition}px;` : `right: ${menuPosition}px;`
        "
        @click="focusEditor"
      >
        <v-menu offset-y :close-on-content-click="true" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1 px-1 background--text btn--nohover btn--nowrap"
              elevation="0"
              color="gray lighten-4"
              plain
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <strong>{{
                getMarkAttrs("font_family").name || "Font family"
              }}</strong>
            </v-btn>
          </template>
          <v-list dense max-height="250px">
            <v-list-item
              v-for="(item, index) in DEFAULT_FONT_FAMILY_MAP"
              :key="index"
              ripple
              dense
              @click="applyFontFamily(commands, item)"
            >
              <v-list-item-title>
                <span v-if="item === EMPTY_FONT_FAMILY">{{ item }}</span>
                <span v-else :style="{ 'font-family': item }">
                  {{ item }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical></v-divider>

        <v-menu offset-y :close-on-content-click="true" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1 px-1 background--text btn--nohover"
              elevation="0"
              color="gray lighten-4"
              plain
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <strong>
                {{ getMarkAttrs("font_size").size || "Font size" }}
              </strong>
            </v-btn>
          </template>
          <v-list dense max-height="250px">
            <v-list-item
              v-for="(item, index) in DEFAULT_FONT_SIZE_MAP"
              :key="index"
              ripple
              dense
              @click="applyFontSize(commands, item, 'px')"
            >
              <v-list-item-title>
                <span> {{ item }} </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical></v-divider>

        <v-menu offset-y :close-on-content-click="true" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <Icon tooltip="Font style">
                vb-bold
              </Icon>
            </BaseButtonIcon>
          </template>
          <div class="menububble--down" @click="focusEditor">
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.bold() ? 'accent' : 'background'"
              @click="commands.bold"
            >
              <Icon tooltip="Bold">
                vb-bold
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.underline() ? 'accent' : 'background'"
              @click="commands.underline"
            >
              <Icon tooltip="Underline">
                vb-underline
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.strike() ? 'accent' : 'background'"
              @click="commands.strike"
            >
              <Icon tooltip="Strike">
                vb-strikethrough
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.italic() ? 'accent' : 'background'"
              @click="commands.italic"
            >
              <Icon tooltip="Italic">
                vb-italic
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.blockquote() ? 'accent' : 'background'"
              @click="commands.blockquote"
            >
              <Icon tooltip="Block quote">
                {{ svgFormatQuoteClose }}
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.code_block() ? 'accent' : 'background'"
              @click="commands.code_block"
            >
              <Icon tooltip="Code">
                {{ svgCodeTags }}
              </Icon>
            </BaseButtonIcon>
          </div>
        </v-menu>

        <v-menu offset-y :close-on-content-click="true" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <Icon tooltip="Alignment">
                vb-align-left
              </Icon>
            </BaseButtonIcon>
          </template>
          <div class="menububble--down" @click="focusEditor">
            <BaseButtonIcon
              class="mx-1"
              :color="
                isActive.paragraph({ align: 'left' }) ||
                isActive.heading({ align: 'left' })
                  ? 'accent'
                  : 'background'
              "
              @click="
                applyTextAlignment(commands, 'left', isActive, getNodeAttrs)
              "
            >
              <Icon tooltip="Align left">
                vb-align-left
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="
                isActive.paragraph({ align: 'center' }) ||
                isActive.heading({ align: 'center' })
                  ? 'accent'
                  : 'background'
              "
              @click="
                applyTextAlignment(commands, 'center', isActive, getNodeAttrs)
              "
            >
              <Icon tooltip="Align center">
                vb-align-center
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="
                isActive.paragraph({ align: 'right' }) ||
                isActive.heading({ align: 'right' })
                  ? 'accent'
                  : 'background'
              "
              @click="
                applyTextAlignment(commands, 'right', isActive, getNodeAttrs)
              "
            >
              <Icon tooltip="Align right">
                vb-align-right
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="
                isActive.paragraph({ align: 'justify' }) ||
                isActive.heading({ align: 'justify' })
                  ? 'accent'
                  : 'background'
              "
              @click="
                applyTextAlignment(commands, 'justify', isActive, getNodeAttrs)
              "
            >
              <Icon tooltip="Justify">
                vb-align-justify
              </Icon>
            </BaseButtonIcon>
          </div>
        </v-menu>

        <v-menu offset-y :close-on-content-click="true" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <Icon tooltip="List">
                vb-bulleted-list
              </Icon>
            </BaseButtonIcon>
          </template>
          <div class="menububble--down" @click="focusEditor">
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.bullet_list() ? 'accent' : 'background'"
              @click="commands.bullet_list"
            >
              <Icon tooltip="Bullet list">
                vb-bulleted-list
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.ordered_list() ? 'accent' : 'background'"
              @click="commands.ordered_list"
            >
              <Icon tooltip="Ordered list">
                vb-numbered-list
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.todo_list() ? 'accent' : 'background'"
              @click="commands.todo_list"
            >
              <Icon tooltip="Todo list">
                vb-todo-list
              </Icon>
            </BaseButtonIcon>
          </div>
        </v-menu>

        <v-menu offset-y :close-on-content-click="true" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <Icon tooltip="Header">
                vb-header-1
              </Icon>
            </BaseButtonIcon>
          </template>
          <div class="menububble--down" @click="focusEditor">
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.heading({ level: 1 }) ? 'accent' : 'background'"
              @click="
                commands.heading({
                  level: 1,
                  align: getNodeAttrs('heading').align
                })
              "
            >
              <Icon tooltip="1 level header">
                vb-header-1
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.heading({ level: 2 }) ? 'accent' : 'background'"
              @click="
                commands.heading({
                  level: 2,
                  align: getNodeAttrs('heading').align
                })
              "
            >
              <Icon tooltip="2 level header">
                vb-header-2
              </Icon>
            </BaseButtonIcon>
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.heading({ level: 3 }) ? 'accent' : 'background'"
              @click="
                commands.heading({
                  level: 3,
                  align: getNodeAttrs('heading').align
                })
              "
            >
              <Icon tooltip="3 level header">
                vb-header-3
              </Icon>
            </BaseButtonIcon>
          </div>
        </v-menu>

        <BaseButtonIcon
          class="mx-1"
          :color="isActive.paragraph() ? 'accent' : 'background'"
          @click="commands.paragraph"
        >
          <Icon tooltip="Paragraph">
            vb-paragraph
          </Icon>
        </BaseButtonIcon>

        <BaseButtonIcon class="mx-1" @click="commands.horizontal_rule">
          <Icon tooltip="Horizontal line">
            {{ svgBorderHorizontal }}
          </Icon>
        </BaseButtonIcon>

        <v-menu
          v-model="menuLink"
          min-width="250"
          offset-y
          :close-on-content-click="false"
          nudge-bottom="12"
        >
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              :color="isActive.link() ? 'accent' : 'background'"
              v-bind="attrs"
              v-on="on"
              @click="showLinkMenu(getMarkAttrs('link'))"
            >
              <Icon :tooltip="isActive.link() ? 'Update Link' : 'Add link'">
                vb-link
              </Icon>
            </BaseButtonIcon>
          </template>
          <div class="menububble--down">
            <form @submit.prevent="setLinkUrl(commands.link, linkUrl)">
              <input
                ref="linkInput"
                v-model="linkUrl"
                type="text"
                placeholder="https://"
                class="input--link ml-1 fs-mask"
              />
              <BaseButtonIcon
                type="button"
                @click="setLinkUrl(commands.link, null)"
              >
                <Icon small tooltip="Remove">
                  {{ svgCloseBox }}
                </Icon>
              </BaseButtonIcon>
            </form>
          </div>
        </v-menu>

        <v-divider vertical></v-divider>

        <v-menu offset-y :close-on-content-click="false" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <v-row align="center" class="flex-column" justify="center">
                <Icon tooltip="Text color">
                  {{ svgFormatColorText }}
                </Icon>
                <v-sheet
                  outlined
                  tile
                  height="4"
                  width="24"
                  :color="getMarkAttrs('text_color').color || 'black'"
                ></v-sheet>
              </v-row>
            </BaseButtonIcon>
          </template>
          <v-color-picker
            v-model="color"
            mode="hexa"
            @input="
              applyTextColor(
                commands,
                $event,
                isActive.text_color({ color: color })
              )
            "
          ></v-color-picker>
        </v-menu>

        <v-menu offset-y :close-on-content-click="false" nudge-bottom="12">
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="focusEditor"
            >
              <v-row align="center" class="flex-column" justify="center">
                <Icon tooltip="Text highlight">
                  vb-marker-pen
                </Icon>
                <v-sheet
                  outlined
                  tile
                  height="4"
                  width="24"
                  :color="getMarkAttrs('highlight_color').color"
                ></v-sheet>
              </v-row>
            </BaseButtonIcon>
          </template>
          <v-color-picker
            v-model="highlightColor"
            mode="hexa"
            @input="
              applyHighlightColor(
                commands,
                $event,
                isActive.highlight_color({ color: highlightColor })
              )
            "
          ></v-color-picker>
        </v-menu>

        <template v-if="!blank && !modal">
          <v-divider vertical></v-divider>

          <v-menu offset-y :close-on-content-click="false" nudge-bottom="12">
            <template v-slot:activator="{ on, attrs }">
              <BaseButtonIcon
                :height="24"
                :width="24"
                outlined
                class="mr-1 ml-2"
                :style="{
                  background: !background.includes('base64')
                    ? background
                    : backgroundColor
                }"
                v-bind="attrs"
                v-on="on"
                @click="focusEditor"
              >
                <Icon tooltip="Background color"> </Icon>
              </BaseButtonIcon>
            </template>
            <v-color-picker
              v-model="backgroundColor"
              mode="hexa"
              @input="applyBackgroundColor()"
            ></v-color-picker>
          </v-menu>
          <CropImage @applyBackgroundImage="applyBackgroundImage" />
        </template>

        <v-divider vertical></v-divider>

        <v-menu
          v-model="menuVideo"
          min-width="250"
          offset-y
          :close-on-content-click="false"
          nudge-bottom="12"
        >
          <template v-slot:activator="{ on, attrs }">
            <BaseButtonIcon
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="showVideoMenu(getMarkAttrs('link'))"
            >
              <Icon tooltip="Add video">
                {{ svgVideo }}
              </Icon>
            </BaseButtonIcon>
          </template>
          <div class="menububble--down">
            <form @submit.prevent="addVideo(commands.iframe, videoUrl)">
              <input
                ref="videoInput"
                v-model="videoUrl"
                type="text"
                placeholder="https://www.youtube.com/"
                class="input--link ml-1 fs-mask"
              />
            </form>
          </div>
        </v-menu>

        <v-divider vertical></v-divider>

        <BaseButtonIcon class="mx-1" @click="commands.undo">
          <Icon tooltip="Undo">
            vb-undo
          </Icon>
        </BaseButtonIcon>
        <BaseButtonIcon class="mx-1" @click="commands.redo">
          <Icon tooltip="Redo">
            vb-redo
          </Icon>
        </BaseButtonIcon>
      </div>
    </editor-menu-bar>

    <v-row no-gutters :class="editorContentClasses">
      <v-col cols="12" class="fs-mask">
        <editor-content :editor="editor" />
      </v-col>
    </v-row>
    <slot v-if="!blank"></slot>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DEFAULT_COLOR, WHITE } from "@/utils/color";
import {
  DEFAULT_FONT_FAMILY_MAP,
  DEFAULT_FONT_SIZE_MAP,
  EMPTY_FONT_FAMILY,
  DEFAULT_FONT_SIZE
} from "@/utils/font";
import TextColor from "@/plugins/tiptap/text-color";
import HighlightColor from "@/plugins/tiptap/highlight-color";
import Paragraph from "@/plugins/tiptap/paragraph";
import Heading from "@/plugins/tiptap/heading";
import FontFamily from "@/plugins/tiptap/font-family";
import FontSize from "@/plugins/tiptap/font-size";
import TodoItem from "@/plugins/tiptap/todo-item";
import Iframe from "@/plugins/tiptap/iframe";
import {
  Bold,
  Italic,
  Strike,
  Underline,
  CodeBlock,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  TodoList
} from "tiptap-extensions";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import CropImage from "@/components/Shared/CropImage";
import userMixin from "@/mixins/user";
import {
  mdiClose,
  mdiPencil,
  mdiFormatQuoteClose,
  mdiFormatColorText,
  mdiCodeTags,
  mdiBorderHorizontal,
  mdiCloseBox,
  mdiVideo
} from "@mdi/js";
import { isCheckboxEditor } from "@/helpers/editorsHelpers";
import { EditorView } from "prosemirror-view";
EditorView.prototype.updateState = function updateState(state) {
  //@ts-ignore
  if (!this.docView) return; // This prevents the matchesNode error on hot reloads
  //@ts-ignore
  this.updateStateInner(state, this.state.plugins != state.plugins);
};

export default {
  name: "BaseEditor",
  components: { EditorContent, EditorMenuBar, CropImage },
  mixins: [userMixin],
  props: {
    content: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    modal: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    },
    textType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editEnabled: false,
      mouseOver: false,
      svgClose: mdiClose,
      svgPencil: mdiPencil,
      svgFormatQuoteClose: mdiFormatQuoteClose,
      svgCodeTags: mdiCodeTags,
      svgBorderHorizontal: mdiBorderHorizontal,
      svgCloseBox: mdiCloseBox,
      svgVideo: mdiVideo,
      svgFormatColorText: mdiFormatColorText,
      menuLeft: true,
      menuPosition: 0,
      closeBtnPosition: 0,
      EMPTY_FONT_FAMILY: EMPTY_FONT_FAMILY,
      DEFAULT_FONT_SIZE_MAP: DEFAULT_FONT_SIZE_MAP,
      DEFAULT_FONT_FAMILY_MAP: DEFAULT_FONT_FAMILY_MAP,
      editor: new Editor({
        editable: false,
        extensions: [
          new Blockquote(),
          new Underline(),
          new Strike(),
          new Italic(),
          new Bold(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Paragraph(),
          new Heading({ levels: [1, 2, 3] }),
          new FontFamily(),
          new FontSize(),
          new TextColor(),
          new HighlightColor(),
          new History(),
          new TodoItem(),
          new TodoList(),
          new Iframe()
        ],
        content: this.content,
        onUpdate: ({ getHTML }) => {
          this.$emit("update:content", getHTML());
          if (!this.modal) {
            this.saveBoard();
          }
        },
        onFocus: () => {
          this.editable && this.calculateToolbarPosition();
        }
      }),
      color: DEFAULT_COLOR,
      highlightColor: WHITE,
      backgroundColor: WHITE,
      linkUrl: null,
      menuLink: false,
      videoUrl: null,
      menuVideo: false
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    hasProgressSlot() {
      return !!this.$slots["progress"];
    },
    editorContentClasses() {
      if (!this.blank && this.hasProgressSlot) return "mx-6 my-10";
      if (!this.blank && !this.hasProgressSlot) return "mx-6 my-8";
      if (this.blank) return "my-4";

      return "";
    },
    hasEditButton() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    checkboxEditor() {
      return isCheckboxEditor(this.textType);
    }
  },
  watch: {
    menuLink: "_focusEditor",
    menuVideo: "_focusEditor",
    hasEditButton(val) {
      this.editor.setOptions({
        editable: !val
      });
      this.editEnabled = !val;
    },
    content(val) {
      if (val && val !== this.editor.getHTML()) {
        this.editor.setContent(val);
      }
    }
  },
  async mounted() {
    await this.isOwner(this.currentBoard?.id);
    this.editor.setOptions({
      editable: this.owner && !this.hasEditButton && this.editable
    });
    if (this.modal && this.editable) this.calculateToolbarPosition();
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    ...mapActions("boards", ["saveBoard"]),
    focusEditor() {
      this.editor.focus();
    },
    outsideClick() {
      this.disableEdition();
    },
    enableEdition() {
      this.editEnabled = true;
      this.editor.setOptions({
        editable: this.owner && this.editable
      });
      this.editor.focus();
    },
    isFocusedEditor() {
      if (this.hasEditButton) {
        return true;
      } else {
        return this.editor.focused;
      }
    },
    disableEdition() {
      this.editEnabled = false;
      if (this.hasEditButton) {
        this.editor.setOptions({
          editable: false
        });
        this.editor.blur();
      }
    },
    closeToolbar() {
      if (this.editor) {
        this.editor.blur();
      }
      this.disableEdition();
    },
    onMouseOver() {
      this.$emit("onMouseOver");
      this.mouseOver = true;
    },
    onMouseLeave() {
      this.$emit("onMouseLeave");
      this.mouseOver = false;
      if (this.editor && !this.editor.focused) {
        this.editor.blur();
      }
    },
    calculateToolbarPosition() {
      if (this.editable) {
        const windowWidth = window.innerWidth - 20;

        const element = this.$refs.menu;
        const parent = this.$refs.editor.$el;

        const box = parent.getBoundingClientRect();
        const el = element.getBoundingClientRect();

        this.menuLeft = true;
        const left = (box.left + box.right) / 2 - box.left;
        let res = 0;

        if (this.modal) {
          res = box.width / 2;
        } else if (box.width > el.width) {
          res = left;
        } else if (box.right - box.width / 2 + el.width / 2 > windowWidth) {
          res = Math.min(
            box.width - el.width / 2,
            Math.max(left, el.width / 2)
          );
        } else if (box.left + box.width / 2 < el.width / 2) {
          this.menuLeft = false;
          res =
            Math.min(box.width - el.width / 2, Math.max(left, el.width / 2)) -
            el.width;
        } else {
          res = left;
        }

        this.menuPosition = Math.round(res);
        this.closeBtnPosition = Math.round(res + el.width / 2);
      }
    },
    applyBackgroundColor() {
      this.$emit("update:background", this.backgroundColor);
      this.saveBoard();
    },
    applyBackgroundImage(dataUrl) {
      this.$emit(
        "update:background",
        `url(${dataUrl}) no-repeat center center`
      );
      this.saveBoard();
    },
    applyTextColor(commands, event, active) {
      if (active) {
        commands.text_color();
      }
      commands.text_color({ color: this.color });
    },
    applyHighlightColor(commands, event, active) {
      if (active) {
        commands.highlight_color();
      }
      commands.highlight_color({ color: this.highlightColor });
    },
    applyFontFamily(commands, font) {
      if (font === EMPTY_FONT_FAMILY) {
        commands.font_family({ name: "Inter" });
      } else {
        commands.font_family({ name: font });
      }
    },
    applyFontSize(commands, size, unit) {
      if (size === DEFAULT_FONT_SIZE) {
        commands.font_size();
      } else {
        commands.font_size({ size: unit ? size + unit : size });
      }
    },
    applyTextAlignment(commands, align, isActive, getNodeAttrs) {
      if (isActive.paragraph()) {
        commands.paragraph({ align: align });
      } else if (isActive.heading()) {
        commands.heading({
          align: align,
          level: getNodeAttrs("heading").level
        });
      }
    },
    showLinkMenu(attrs) {
      this.menuLink = true;
      this.linkUrl = attrs.href;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.menuLink = false;
      this.linkUrl = null;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    showVideoMenu(attrs) {
      this.menuVideo = true;
      this.videoUrl = attrs.href;
      this.$nextTick(() => {
        this.$refs.videoInput.focus();
      });
    },
    hideVideoMenu() {
      this.menuVideo = false;
      this.videoUrl = null;
    },
    addVideo(command, url) {
      const id = this._youtubeParser(url);
      if (id) command({ src: `https://www.youtube.com/embed/${id}` });
      this.hideVideoMenu();
    },
    addJSONContent(content) {
      const doc = this.editor.getJSON();
      doc.content = doc.content.filter(x => false);
      doc.content.push(content);
      this.editor.setContent(doc);
      this.$emit("update:content", this.editor.getHTML());
      this.saveBoard();
    },
    selectFile() {
      this.$refs.file.$refs.input.click();
    },
    _youtubeParser(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    _focusEditor(val) {
      if (!val) {
        this.focusEditor();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$menu-height: 50px;

.editor-card {
  background-size: cover !important;
  box-shadow: none !important;
}
.editor-card--focused {
  box-shadow: inset 0px 0px 0px 3px var(--v-primary-lighten4) !important;
}
::v-deep .ProseMirror {
  outline: 0 !important;
  word-break: break-word;
  hr {
    box-sizing: border-box;
    border: 1px solid #ececec;
  }
}
::v-deep .v-toolbar__content {
  padding: 0px;
  height: auto !important;
}
.v-list-item {
  background-color: white;
}

::v-deep ul[data-type="todo_list"] {
  padding-left: 0;
}
::v-deep li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 18px;
}
::v-deep .todo-checkbox {
  height: 1.25em;
  width: 1.35em;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: -4px;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  background-color: transparent;
  transition: 0.4s var(--v-gray-lighten4);
  &:before {
    content: "\e909";
    font-family: "Visionbook";
    font-size: 21px;
  }
}
::v-deep .todo-content {
  flex: 1;
  > p:last-of-type {
    margin-bottom: 0 !important;
  }
  > p {
    margin-top: 0 !important;
  }
  > ul[data-type="todo_list"] {
    margin: 0.5rem 0;
  }
}
::v-deep li[data-done="true"] {
  > .todo-checkbox {
    &:before {
      content: "\e90a";
      font-family: "Visionbook";
      font-size: 21px;
    }
  }
}
::v-deep li:nth-child(3n + 1) {
  > .todo-checkbox {
    color: $secondary;
  }
}
::v-deep li:nth-child(3n + 2) {
  > .todo-checkbox {
    color: $secondary;
  }
}
::v-deep li:nth-child(3n + 3) {
  > .todo-checkbox {
    color: $secondary;
  }
}

.v-menu__content {
  background-color: var(--v-gray-lighten4);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.16),
    0 8px 16px 0 rgba(0, 0, 0, 0.12);
}
.btn--nohover {
  &:before {
    background-color: var(--v-gray-lighten4) !important;
  }
}
.btn--nowrap {
  ::v-deep > span {
    text-overflow: ellipsis;
    display: inline-block;
    width: 90px;
    white-space: nowrap;
    overflow: hidden !important;
  }
}
.btn--close {
  position: absolute;
  display: flex;
  opacity: 0.7;
  transform: translateX(-50%);
  top: -72px;
  z-index: 9999;
}

.btn--edit {
  position: absolute;
  z-index: 7;
  right: 10px;
  top: 10px;
}
.btn--close-mobile {
  top: -22px !important;
}
.menububble--down {
  align-items: center;
  display: flex;
  height: $menu-height;
  padding: 0.3rem;
}
.menububble {
  height: $menu-height;
  align-items: center;
  bottom: 100%;
  position: absolute;
  display: flex;
  z-index: 20;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  visibility: hidden;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  background: var(--v-gray-lighten4);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.16),
    0 8px 16px 0 rgba(0, 0, 0, 0.12);
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  button {
    cursor: pointer;
  }
  form {
    display: flex;
    align-items: center;
  }
  input {
    font: inherit !important;
    border: none !important;
  }
}
.menububble--mobile {
  min-height: $menu-height;
  left: 0 !important;
  right: 0 !important;
  height: auto;
  align-items: center;
  bottom: 100%;
  position: absolute;
  z-index: 20;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  background: var(--v-gray-lighten4);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.16),
    0 8px 16px 0 rgba(0, 0, 0, 0.12);
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  button {
    cursor: pointer;
  }
  form {
    display: flex;
    align-items: center;
  }
  input {
    font: inherit !important;
    border: none !important;
  }
}

.input--link {
  outline: none;
  min-width: 200px;
}

::v-deep ul li::marker {
  color: var(--v-secondary-base);
  font-size: 1.2em;
}

::v-deep .iframe__embed {
  width: 100%;
  height: 15rem;
  border: 0;
  padding-bottom: 10px;
}
</style>

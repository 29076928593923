var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideClick),expression:"outsideClick"}],ref:"editor",class:{
    'editor-card': !_vm.blank,
    'editor-card--focused':
      _vm.editor.focused && _vm.editable && (_vm.editEnabled || !_vm.hasEditButton) && !_vm.blank
  },style:({
    background: _vm.blank ? 'transparent' : _vm.background
  }),attrs:{"outlined":_vm.modal,"flat":_vm.blank,"data-testid":_vm.checkboxEditor ? 'checkbox-editor' : ''},on:{"mouseover":_vm.onMouseOver,"mouseleave":_vm.onMouseLeave},nativeOn:{"click":function($event){return _vm.focusEditor($event)}}},[(
      _vm.tooltip &&
        _vm.mouseOver &&
        (_vm.editEnabled || !_vm.hasEditButton) &&
        _vm.editable &&
        !_vm.blank
    )?_c('Questionmark',{attrs:{"tooltip":_vm.tooltip,"styles":{ position: 'absolute', left: '10px', top: '10px', zIndex: '7' }}}):_vm._e(),(_vm.mouseOver && !_vm.editEnabled && _vm.hasEditButton && _vm.owner && _vm.editable)?_c('BaseButtonIcon',{staticClass:"btn--edit",attrs:{"size":"small"},on:{"click":_vm.enableEdition}},[_c('Icon',{attrs:{"tooltip":_vm.$t('board.edit_field')}},[_vm._v(_vm._s(_vm.svgPencil))])],1):_vm._e(),(_vm.blank)?_vm._t("actions"):_vm._e(),(!_vm.blank)?_vm._t("progress"):_vm._e(),(
      !_vm.modal &&
        _vm.owner &&
        _vm.editable &&
        _vm.isFocusedEditor() &&
        (_vm.editEnabled || !_vm.hasEditButton)
    )?_c('v-btn',{staticClass:"white--text btn--close",class:{
      'btn--close-mobile': _vm.$vuetify.breakpoint.smAndDown
    },style:(_vm.menuLeft
        ? ("left: " + _vm.closeBtnPosition + "px;")
        : ("right: " + (_vm.closeBtnPosition - 32) + "px;")),attrs:{"fab":"","x-small":"","color":"secondary"},on:{"click":_vm.closeToolbar}},[_c('Icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.svgClose))])],1):_vm._e(),(_vm.editable)?_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var commands = ref.commands;
    var isActive = ref.isActive;
    var getMarkAttrs = ref.getMarkAttrs;
    var getNodeAttrs = ref.getNodeAttrs;
    var focused = ref.focused;
return [_c('div',{ref:"menu",class:{
        'is-active': focused && _vm.owner && (_vm.editEnabled || !_vm.hasEditButton),
        menububble: !_vm.$vuetify.breakpoint.smAndDown,
        'menububble--mobile': _vm.$vuetify.breakpoint.smAndDown
      },style:(_vm.menuLeft ? ("left: " + _vm.menuPosition + "px;") : ("right: " + _vm.menuPosition + "px;")),on:{"click":_vm.focusEditor}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 px-1 background--text btn--nohover btn--nowrap",attrs:{"elevation":"0","color":"gray lighten-4","plain":""},on:{"click":_vm.focusEditor}},'v-btn',attrs,false),on),[_c('strong',[_vm._v(_vm._s(getMarkAttrs("font_family").name || "Font family"))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":"","max-height":"250px"}},_vm._l((_vm.DEFAULT_FONT_FAMILY_MAP),function(item,index){return _c('v-list-item',{key:index,attrs:{"ripple":"","dense":""},on:{"click":function($event){return _vm.applyFontFamily(commands, item)}}},[_c('v-list-item-title',[(item === _vm.EMPTY_FONT_FAMILY)?_c('span',[_vm._v(_vm._s(item))]):_c('span',{style:({ 'font-family': item })},[_vm._v(" "+_vm._s(item)+" ")])])],1)}),1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 px-1 background--text btn--nohover",attrs:{"elevation":"0","color":"gray lighten-4","plain":""},on:{"click":_vm.focusEditor}},'v-btn',attrs,false),on),[_c('strong',[_vm._v(" "+_vm._s(getMarkAttrs("font_size").size || "Font size")+" ")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":"","max-height":"250px"}},_vm._l((_vm.DEFAULT_FONT_SIZE_MAP),function(item,index){return _c('v-list-item',{key:index,attrs:{"ripple":"","dense":""},on:{"click":function($event){return _vm.applyFontSize(commands, item, 'px')}}},[_c('v-list-item-title',[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])],1)}),1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"Font style"}},[_vm._v(" vb-bold ")])],1)]}}],null,true)},[_c('div',{staticClass:"menububble--down",on:{"click":_vm.focusEditor}},[_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.bold() ? 'accent' : 'background'},on:{"click":commands.bold}},[_c('Icon',{attrs:{"tooltip":"Bold"}},[_vm._v(" vb-bold ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.underline() ? 'accent' : 'background'},on:{"click":commands.underline}},[_c('Icon',{attrs:{"tooltip":"Underline"}},[_vm._v(" vb-underline ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.strike() ? 'accent' : 'background'},on:{"click":commands.strike}},[_c('Icon',{attrs:{"tooltip":"Strike"}},[_vm._v(" vb-strikethrough ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.italic() ? 'accent' : 'background'},on:{"click":commands.italic}},[_c('Icon',{attrs:{"tooltip":"Italic"}},[_vm._v(" vb-italic ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.blockquote() ? 'accent' : 'background'},on:{"click":commands.blockquote}},[_c('Icon',{attrs:{"tooltip":"Block quote"}},[_vm._v(" "+_vm._s(_vm.svgFormatQuoteClose)+" ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.code_block() ? 'accent' : 'background'},on:{"click":commands.code_block}},[_c('Icon',{attrs:{"tooltip":"Code"}},[_vm._v(" "+_vm._s(_vm.svgCodeTags)+" ")])],1)],1)]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"Alignment"}},[_vm._v(" vb-align-left ")])],1)]}}],null,true)},[_c('div',{staticClass:"menububble--down",on:{"click":_vm.focusEditor}},[_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.paragraph({ align: 'left' }) ||
              isActive.heading({ align: 'left' })
                ? 'accent'
                : 'background'},on:{"click":function($event){return _vm.applyTextAlignment(commands, 'left', isActive, getNodeAttrs)}}},[_c('Icon',{attrs:{"tooltip":"Align left"}},[_vm._v(" vb-align-left ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.paragraph({ align: 'center' }) ||
              isActive.heading({ align: 'center' })
                ? 'accent'
                : 'background'},on:{"click":function($event){return _vm.applyTextAlignment(commands, 'center', isActive, getNodeAttrs)}}},[_c('Icon',{attrs:{"tooltip":"Align center"}},[_vm._v(" vb-align-center ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.paragraph({ align: 'right' }) ||
              isActive.heading({ align: 'right' })
                ? 'accent'
                : 'background'},on:{"click":function($event){return _vm.applyTextAlignment(commands, 'right', isActive, getNodeAttrs)}}},[_c('Icon',{attrs:{"tooltip":"Align right"}},[_vm._v(" vb-align-right ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.paragraph({ align: 'justify' }) ||
              isActive.heading({ align: 'justify' })
                ? 'accent'
                : 'background'},on:{"click":function($event){return _vm.applyTextAlignment(commands, 'justify', isActive, getNodeAttrs)}}},[_c('Icon',{attrs:{"tooltip":"Justify"}},[_vm._v(" vb-align-justify ")])],1)],1)]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"List"}},[_vm._v(" vb-bulleted-list ")])],1)]}}],null,true)},[_c('div',{staticClass:"menububble--down",on:{"click":_vm.focusEditor}},[_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.bullet_list() ? 'accent' : 'background'},on:{"click":commands.bullet_list}},[_c('Icon',{attrs:{"tooltip":"Bullet list"}},[_vm._v(" vb-bulleted-list ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.ordered_list() ? 'accent' : 'background'},on:{"click":commands.ordered_list}},[_c('Icon',{attrs:{"tooltip":"Ordered list"}},[_vm._v(" vb-numbered-list ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.todo_list() ? 'accent' : 'background'},on:{"click":commands.todo_list}},[_c('Icon',{attrs:{"tooltip":"Todo list"}},[_vm._v(" vb-todo-list ")])],1)],1)]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"Header"}},[_vm._v(" vb-header-1 ")])],1)]}}],null,true)},[_c('div',{staticClass:"menububble--down",on:{"click":_vm.focusEditor}},[_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.heading({ level: 1 }) ? 'accent' : 'background'},on:{"click":function($event){commands.heading({
                level: 1,
                align: getNodeAttrs('heading').align
              })}}},[_c('Icon',{attrs:{"tooltip":"1 level header"}},[_vm._v(" vb-header-1 ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.heading({ level: 2 }) ? 'accent' : 'background'},on:{"click":function($event){commands.heading({
                level: 2,
                align: getNodeAttrs('heading').align
              })}}},[_c('Icon',{attrs:{"tooltip":"2 level header"}},[_vm._v(" vb-header-2 ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.heading({ level: 3 }) ? 'accent' : 'background'},on:{"click":function($event){commands.heading({
                level: 3,
                align: getNodeAttrs('heading').align
              })}}},[_c('Icon',{attrs:{"tooltip":"3 level header"}},[_vm._v(" vb-header-3 ")])],1)],1)]),_c('BaseButtonIcon',{staticClass:"mx-1",attrs:{"color":isActive.paragraph() ? 'accent' : 'background'},on:{"click":commands.paragraph}},[_c('Icon',{attrs:{"tooltip":"Paragraph"}},[_vm._v(" vb-paragraph ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",on:{"click":commands.horizontal_rule}},[_c('Icon',{attrs:{"tooltip":"Horizontal line"}},[_vm._v(" "+_vm._s(_vm.svgBorderHorizontal)+" ")])],1),_c('v-menu',{attrs:{"min-width":"250","offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":isActive.link() ? 'accent' : 'background'},on:{"click":function($event){_vm.showLinkMenu(getMarkAttrs('link'))}}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":isActive.link() ? 'Update Link' : 'Add link'}},[_vm._v(" vb-link ")])],1)]}}],null,true),model:{value:(_vm.menuLink),callback:function ($$v) {_vm.menuLink=$$v},expression:"menuLink"}},[_c('div',{staticClass:"menububble--down"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"input--link ml-1 fs-mask",attrs:{"type":"text","placeholder":"https://"},domProps:{"value":(_vm.linkUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),_c('BaseButtonIcon',{attrs:{"type":"button"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, null)}}},[_c('Icon',{attrs:{"small":"","tooltip":"Remove"}},[_vm._v(" "+_vm._s(_vm.svgCloseBox)+" ")])],1)],1)])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('Icon',{attrs:{"tooltip":"Text color"}},[_vm._v(" "+_vm._s(_vm.svgFormatColorText)+" ")]),_c('v-sheet',{attrs:{"outlined":"","tile":"","height":"4","width":"24","color":getMarkAttrs('text_color').color || 'black'}})],1)],1)]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa"},on:{"input":function($event){_vm.applyTextColor(
              commands,
              $event,
              isActive.text_color({ color: _vm.color })
            )}},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('Icon',{attrs:{"tooltip":"Text highlight"}},[_vm._v(" vb-marker-pen ")]),_c('v-sheet',{attrs:{"outlined":"","tile":"","height":"4","width":"24","color":getMarkAttrs('highlight_color').color}})],1)],1)]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa"},on:{"input":function($event){_vm.applyHighlightColor(
              commands,
              $event,
              isActive.highlight_color({ color: _vm.highlightColor })
            )}},model:{value:(_vm.highlightColor),callback:function ($$v) {_vm.highlightColor=$$v},expression:"highlightColor"}})],1),(!_vm.blank && !_vm.modal)?[_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mr-1 ml-2",style:({
                background: !_vm.background.includes('base64')
                  ? _vm.background
                  : _vm.backgroundColor
              }),attrs:{"height":24,"width":24,"outlined":""},on:{"click":_vm.focusEditor}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"Background color"}})],1)]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa"},on:{"input":function($event){return _vm.applyBackgroundColor()}},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}})],1),_c('CropImage',{on:{"applyBackgroundImage":_vm.applyBackgroundImage}})]:_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"min-width":"250","offset-y":"","close-on-content-click":false,"nudge-bottom":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('BaseButtonIcon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":function($event){_vm.showVideoMenu(getMarkAttrs('link'))}}},'BaseButtonIcon',attrs,false),on),[_c('Icon',{attrs:{"tooltip":"Add video"}},[_vm._v(" "+_vm._s(_vm.svgVideo)+" ")])],1)]}}],null,true),model:{value:(_vm.menuVideo),callback:function ($$v) {_vm.menuVideo=$$v},expression:"menuVideo"}},[_c('div',{staticClass:"menububble--down"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addVideo(commands.iframe, _vm.videoUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.videoUrl),expression:"videoUrl"}],ref:"videoInput",staticClass:"input--link ml-1 fs-mask",attrs:{"type":"text","placeholder":"https://www.youtube.com/"},domProps:{"value":(_vm.videoUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.videoUrl=$event.target.value}}})])])]),_c('v-divider',{attrs:{"vertical":""}}),_c('BaseButtonIcon',{staticClass:"mx-1",on:{"click":commands.undo}},[_c('Icon',{attrs:{"tooltip":"Undo"}},[_vm._v(" vb-undo ")])],1),_c('BaseButtonIcon',{staticClass:"mx-1",on:{"click":commands.redo}},[_c('Icon',{attrs:{"tooltip":"Redo"}},[_vm._v(" vb-redo ")])],1)],2)]}}],null,false,3157927488)}):_vm._e(),_c('v-row',{class:_vm.editorContentClasses,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fs-mask",attrs:{"cols":"12"}},[_c('editor-content',{attrs:{"editor":_vm.editor}})],1)],1),(!_vm.blank)?_vm._t("default"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import { fabric } from "fabric";
import {
  ImageScalingFactor,
  ImageSizeMaxBoundaries
} from "@/options/imageOptions";
import { CanvasOptions } from "@/store/types";

interface File {
  type: string;
  name: string;
  size: number;
}

interface ImageScalingOptions {
  factor: ImageScalingOptions;
  size: string;
}

/**
 * Returns high resolution image of the canvas.
 * If you need something bigger than just canvas.toDataURL() (which on mobile, can have poor quality),
 * use this method by providing images promises directly from board config.
 * @param images
 * @param options
 */
export const createCanvasImage = async (images: any, options: CanvasOptions) =>
  new Promise(resolve => {
    const defaultOptions = {
      format: "image/png",
      quality: 0.92,
      width: undefined,
      height: undefined,
      Canvas: undefined,
      crossOrigin: undefined
    };

    options = Object.assign({}, defaultOptions, options);
    const canvas = window.document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
      resolve(
        Promise.all(images).then(imagesRes => {
          canvas.width = options.width;
          canvas.height = options.height;
          imagesRes.forEach((image: any) => {
            ctx.globalAlpha = image.opacity ? image.opacity : 1;
            return ctx.drawImage(
              image.img,
              image.left - image.width / 2 || 0,
              image.top - image.height / 2 || 0,
              image.width,
              image.height
            );
          });
          return canvas.toDataURL("image/png", 0.92);
        })
      );
    }
  });

export const isImage = (file: File) => {
  return (
    file && ["image/gif", "image/jpeg", "image/png"].includes(file["type"])
  );
};

/**
 * Resize image based on provided width and height
 * @param {String} base64 - The base64 string (must include MIME type)
 * @param {Number} nextWidth - next width in pixels
 * @param {Number} nextHeight - next height in pixels
 */
export async function resizeImage(
  base64: string,
  nextWidth: number,
  nextHeight: number
) {
  return new Promise(resolve => {
    const canvas = document.createElement("canvas");
    canvas.width = nextWidth;
    canvas.height = nextHeight;
    const context = canvas.getContext("2d");
    const img = document.createElement("img");
    img.src = base64;
    img.onload = function() {
      context?.scale(nextWidth / img.width, nextHeight / img.height);
      context?.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
}

export const formatFileSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * Returns file size in bytes
 * @param base64
 */
export const getFileLengthInBytes = (base64: string) => {
  if (base64.length <= 0) return 0;
  return parseInt(String(base64.replace(/=/g, "").length * 0.75));
};

export const getImageScalingOptions = (bytes: number) => {
  const sizesInfo = formatFileSize(bytes);
  if (bytes <= ImageSizeMaxBoundaries.SMALL) {
    return {
      factor: ImageScalingFactor.SMALL,
      sizesInfo: `${sizesInfo} - small`
    };
  }
  if (bytes <= ImageSizeMaxBoundaries.MEDIUM) {
    return {
      factor: ImageScalingFactor.MEDIUM,
      sizesInfo: `${sizesInfo} - medium`
    };
  }

  return {
    factor: ImageScalingFactor.LARGE,
    sizesInfo: `${sizesInfo} - large`
  };
};

const moveableImageOptions = [
  "lockMovementX",
  "lockMovementY",
  "lockRotation",
  "lockScalingX",
  "lockScalingY"
];

const moveableImageVisibilityOptions = [
  "hasControls",
  "hasBorders",
  "hasBorders",
  "hasRotatingPoint"
];

export const disableImageEdition = (image: fabric.Image) => {
  moveableImageOptions.forEach((option: string) => {
    const optionKey = option as keyof fabric.Image;
    image[optionKey] = true;
  });
  moveableImageVisibilityOptions.forEach((option: string) => {
    const optionKey = option as keyof fabric.Image;
    image[optionKey] = false;
  });
};

export const enableImageEdition = (image: fabric.Image) => {
  moveableImageOptions.forEach((option: string) => {
    const optionKey = option as keyof fabric.Image;
    image[optionKey] = false;
  });
  moveableImageVisibilityOptions.forEach((option: string) => {
    const optionKey = option as keyof fabric.Image;
    image[optionKey] = true;
  });
};

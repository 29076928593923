// @ts-nocheck
import { Mark } from "tiptap";
import { toggleMark } from "tiptap-commands";
import { COLOR_SET } from "@/utils/color";

export default class HighlightColor extends Mark {
  get name() {
    return "highlight_color";
  }

  get defaultOptions() {
    return {
      colors: COLOR_SET.flat()
    };
  }

  get schema() {
    return {
      attrs: {
        color: {
          default: ""
        }
      },
      inline: true,
      group: "inline",
      parseDOM: [
        {
          style: "background-color",
          getAttrs: (color: string) => {
            return {
              color
            };
          }
        }
      ],
      toDOM(node) {
        const { color } = node.attrs;
        const attrs: { style?: string } = {};

        if (color) {
          attrs.style = `background-color: ${color};`;
        }

        return ["span", attrs, 0];
      }
    };
  }
  commands({ type }) {
    return attrs => toggleMark(type, attrs);
  }
}

// @ts-nocheck
import { Node } from "tiptap";
import { toggleBlockType } from "tiptap-commands";

export default class Paragraph extends Node {
  get name(): string {
    return "paragraph";
  }

  get defaultOptions() {
    return {
      aligns: ["left", "center", "right", "justify"]
    };
  }

  get schema() {
    return {
      content: "inline*",
      group: "block",
      defining: true,
      draggable: false,
      attrs: {
        align: {
          default: "left"
        }
      },
      parseDOM: [
        {
          tag: "p",
          getAttrs: node => ({
            align: node.style.textAlign || "left"
          })
        }
      ],
      toDOM: node => ["p", { style: `text-align: ${node.attrs.align}` }, 0]
    };
  }

  public commands({ type, schema }) {
    return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs);
  }
}

import { BlockConfig } from "@/options/blockOptions";
import vuetify from "@/plugins/vuetify";

export interface ImageConfig {
  name: string;
  imageId: string;
  src: string;
  angle: number;
  width: number;
  height: number;
  filters: object;
  left: number;
  top: number;
}

export interface ImagesBlockConfig extends BlockConfig {
  viewportTransform: Array<number>;
  zoom: number;
  images: Array<ImageConfig>;
}

export const enum CanvasZoomBoundaries {
  MIN = 0.5,
  MAX = 2.0
}

// in bytes
export const enum ImageSizeMaxBoundaries {
  LARGE = 20971520, // 20MB
  MEDIUM = 8388608, // 8MB
  SMALL = 3145728 // 3MB
}

export const enum ImageScalingFactor {
  LARGE = 0.15,
  MEDIUM = 0.25,
  SMALL = 0.8
}

export const imageControlsVisibilityOptions = {
  mtr: true,
  mbr: true,
  br: true,
  bl: true,
  mb: false,
  ml: false,
  mr: false,
  mt: false,
  tl: true,
  tr: true
};

export const imageStaticOptions = {
  centeredScaling: false,
  originX: "center",
  originY: "center"
};

export const imageSelectionOptions = {
  borderColor: vuetify.framework.theme.currentTheme.primary,
  cornerColor: vuetify.framework.theme.currentTheme.primary,
  cornerSize: 15,
  borderSize: 3
};

export interface FabricControlsAppearance {
  borderColor: string;
  cornerColor: string;
  cornerSize: number;
  borderSize: number;
}

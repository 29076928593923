export const isCheckboxEditor = (type: string) => {
  return type === "progress";
};

/**
 * Let's keep that as it's useful for debugging/enhancing journal component.
 */
// export const getFakePosts = () => {
//   const posts = [];
//   for (let i = 1; i <= 5; i++) {
//     const today = moment();
//     const prev = today.subtract(i, "days");
//     posts.push({
//       postId: uuid.v4(),
//       createdAt: prev.unix(),
//       content:
//         "<h2>Daily journal:</h2><p>What did I experience today that I would like to remember?</p><p><strong>Fixing another bug :)</strong></p><p>What will I keep doing, stop doing and start doing?</p><p><strong>I will try to stop drinking coffee</strong></p><p>What am I grateful for today?</p><p><strong>Friends and music that makes me happy</strong></p>"
//     });
//   }
//
//   return posts;
// };

import { ImageFilter } from "@/store/types";
import { fabric } from "fabric";

export const applyImageFilter = (filter: ImageFilter) => {
  const { options } = filter;
  // @ts-ignore
  return new fabric.Image.filters.Composed({
    filterKey: filter.key,
    filterName: filter.name,
    subFilters: [
      new fabric.Image.filters.Brightness({ brightness: options.brightness }),
      new fabric.Image.filters.Contrast({ contrast: options.contrast }),
      // @ts-ignore
      new fabric.Image.filters.Blur({ blur: options.blur }),
      new fabric.Image.filters.Saturation({ saturation: options.saturation }),
      // @ts-ignore
      new fabric.Image.filters.HueRotation({ rotation: options.rotation })
    ]
  });
};

// @ts-nocheck
import { Mark } from "tiptap";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { DEFAULT_FONT_FAMILY_MAP } from "@/utils/font";
import { toggleMark } from "tiptap-commands";

export default class FontFamily extends Mark {
  get name() {
    return "font_family";
  }

  get defaultOptions() {
    return {
      fonts: DEFAULT_FONT_FAMILY_MAP
    };
  }

  get schema() {
    return {
      attrs: {
        name: {
          default: ""
        }
      },
      inline: true,
      group: "inline",
      parseDOM: [
        {
          style: "font-family",
          getAttrs: (name: string) => {
            return {
              name: name ? name.replace(/["']/g, "") : ""
            };
          }
        }
      ],
      toDOM(node: ProsemirrorNode) {
        const { name } = node.attrs;
        const attrs: { style?: string } = {};

        if (name && name !== "Apercu") {
          attrs.style = `font-family: ${name}`;
        }
        return ["span", attrs, 0];
      }
    };
  }
  commands({ type }) {
    return attrs => toggleMark(type, attrs);
  }
}

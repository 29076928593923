import { ImageFilter, ImageFilterOptions } from "@/store/types";

export const initialFiltersConfig = [
  {
    name: "Default",
    key: "default",
    options: {
      brightness: 0,
      contrast: 0,
      blur: 0,
      saturation: 0,
      rotation: 0
    } as ImageFilterOptions
  },
  {
    name: "Dark",
    key: "dark",
    options: {
      brightness: -0.25,
      contrast: -0.15,
      blur: 0.01,
      saturation: -0.66,
      rotation: 0
    } as ImageFilterOptions
  } as ImageFilter,
  {
    name: "Cold",
    key: "cold",
    options: {
      brightness: -0.05,
      contrast: -0.2,
      blur: 0.03,
      saturation: -0.75,
      rotation: 0.55
    } as ImageFilterOptions
  } as ImageFilter,
  {
    name: "Black & white",
    key: "black_and_white",
    options: {
      brightness: 0.12,
      contrast: 0.06,
      blur: 0.03,
      saturation: -1,
      rotation: 0
    } as ImageFilterOptions
  } as ImageFilter,
  {
    name: "Warm",
    key: "warm",
    options: {
      brightness: 0.05,
      contrast: 0.1,
      blur: 0.04,
      saturation: 0.35,
      rotation: 0.08
    } as ImageFilterOptions
  } as ImageFilter
];

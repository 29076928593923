import { fabric } from "fabric";
import vuetify from "@/plugins/vuetify";
// @ts-ignore
import colorcolor from "colorcolor";
import {
  imageSelectionOptions,
  FabricControlsAppearance
} from "@/options/imageOptions";

const selectionOpacity = 0.333;

export const createFabricCanvas = (elementId: string, options: object) => {
  const canvas = new fabric.Canvas(elementId, {
    ...options
  });

  Object.keys(imageSelectionOptions).forEach((key: string) => {
    const id = key as keyof FabricControlsAppearance;

    // @ts-ignore
    fabric.Object.prototype[id] = imageSelectionOptions[id];
  });

  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.originX = fabric.Object.prototype.originY = "center";

  //@ts-ignore
  fabric.Canvas.prototype.getAbsoluteCoords = function(object) {
    const zoom = canvas.getZoom();
    return {
      left: object.left,
      top: object.top,
      width: object.scaleX * object.width * zoom,
      height: object.scaleY * object.height * zoom
    };
  };

  const primaryColorRGB = colorcolor(
    vuetify.framework.theme.currentTheme.primary?.toString(),
    "rgba"
  );

  const applyRGBAlpha = (rgb: string, opacity: number) => {
    return `${rgb.substring(0, rgb.length - 2)}${opacity})`;
  };

  canvas.selectionColor = applyRGBAlpha(primaryColorRGB, selectionOpacity);
  canvas.uniformScaling = true;
  canvas.selectionBorderColor = vuetify.framework.theme.currentTheme.secondary?.toString();
  canvas.selectionLineWidth = 1;
  canvas.allowTouchScrolling = true;

  return canvas;
};

export const disableCanvasEdition = (canvas: fabric.Canvas) => {
  canvas.selection = false;
};

export const enableCanvasEdition = (canvas: fabric.Canvas) => {
  canvas.selection = true;
};

<template>
  <span>
    <v-file-input
      v-show="false"
      ref="file"
      accept="image/*"
      hide-input
      dense
      @change="setImage"
    >
    </v-file-input>
    <slot :selectFile="selectFile">
      <BaseButtonIcon class="mx-1" @click="selectFile">
        <Icon tooltip="Background image">
          {{ svgFileImage }}
        </Icon>
      </BaseButtonIcon>
    </slot>
    <BaseModal
      v-model="dialog"
      :max-width="dialogMaxWidth"
      :max-height="dialogMaxHeight"
      :close="false"
      persistent
      :disabled="!imgSrc"
      :hide-actions="false"
      title="Crop image"
      class="fs-block"
    >
      <vue-cropper
        v-if="dialog"
        ref="cropper"
        :guides="false"
        :view-mode="0"
        drag-mode="move"
        :auto-crop-area="1"
        :min-container-width="250"
        :min-container-height="180"
        :background="true"
        :rotatable="true"
        :src="imgSrc"
        alt="Source image"
        :modal="true"
        :center="false"
        :highlight="true"
      />
      <template v-slot:actions>
        <v-card-actions>
          <BaseButtonIcon class="mx-1" @click="rotate('r')">
            <Icon color="background" tooltip="Rotate right">
              {{ svgRotateRight }}
            </Icon>
          </BaseButtonIcon>
          <BaseButtonIcon class="mx-1" @click="rotate('l')">
            <Icon color="background" tooltip="Rotate left">
              {{ svgRotateLeft }}
            </Icon>
          </BaseButtonIcon>
          <v-spacer></v-spacer>
          <BaseButton outlined color="gray" @click="cancel">
            {{ $t("common.cancel") }}
          </BaseButton>
          <BaseButton @click="cropImage">
            {{ $t("common.save") }}
          </BaseButton>
        </v-card-actions>
      </template>
    </BaseModal>
  </span>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxBackgroundImageSizeMB,
  targetBackgroundImageSizeMB
} from "@/options/editorOptions";
import { mdiFileImage, mdiRotateRight, mdiRotateLeft } from "@mdi/js";
import { notificationStatus } from "@/options/notificationOptions";

export default {
  name: "CropImage",
  components: {
    VueCropper: () => import(/* webpackPrefetch: true */ "vue-cropperjs")
  },
  props: {
    dialogMaxWidth: { default: "600px" },
    dialogMaxHeight: { default: "0.8vh" },
    maxWidth: { default: 1920 },
    maxHeight: { default: 1200 }
  },
  data() {
    return {
      imgSrc: "",
      dialog: false,
      quality: 0.95,
      svgFileImage: mdiFileImage,
      svgRotateRight: mdiRotateRight,
      svgRotateLeft: mdiRotateLeft
    };
  },
  methods: {
    ...mapActions("notifications", ["notification"]),
    selectFile() {
      this.$refs.file.$refs.input.click();
    },
    clearFile() {
      this.$refs.file.$refs.input.value = null;
    },
    setImage(file) {
      if (!file) return;

      if (file.size > maxBackgroundImageSizeMB * 1000000) {
        this.notification({
          ...notificationStatus.ERROR,
          message: this.$t("board.max_bg_size", {
            mb: maxBackgroundImageSizeMB
          })
        });
        return;
      }

      if (!file.type.includes("image/")) {
        this.notification({
          ...notificationStatus.ERROR,
          message: this.$t("board.valid_bg_format")
        });
        return;
      }

      if (file.size > targetBackgroundImageSizeMB * 1000000) {
        this.quality = (
          (targetBackgroundImageSizeMB * 1000000) /
          file.size
        ).toFixed(2);
      }

      const reader = new FileReader();
      reader.onload = event => {
        this.imgSrc = event.target.result;
        // rebuild cropperjs with the updated source
        this.$refs.cropper?.replace(event.target.result);
      };
      reader.readAsDataURL(file);

      this.dialog = true;
    },
    cropImage() {
      // get image data for post processing
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight
        })
        .toBlob(
          async blob => {
            const dataUrl = await new Promise(resolve => {
              const reader = new FileReader();
              reader.onload = () => {
                return resolve(reader.result);
              };
              reader.readAsDataURL(blob);
            });
            this.$emit("applyBackgroundImage", dataUrl);
          },
          "image/jpeg",
          this.quality
        );
      this.cancel();
    },
    rotate(dir) {
      if (dir === "r") {
        this.$refs.cropper.rotate(90);
      } else {
        this.$refs.cropper.rotate(-90);
      }
    },
    flip(vert) {
      const { scaleX, scaleY, rotate } = this.$refs.cropper.getData();
      // when image is rotated, direction must be flipped
      if (rotate === 90 || rotate === 270) {
        vert = !vert;
      }
      if (vert) {
        this.$refs.cropper.scale(scaleX, -1 * scaleY);
      } else {
        this.$refs.cropper.scale(-1 * scaleX, scaleY);
      }
    },
    cancel() {
      this.dialog = false;
      this.clearFile();
    }
  }
};
</script>

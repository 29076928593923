// @ts-nocheck
import { Mark } from "tiptap";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { DEFAULT_FONT_SIZE_MAP } from "@/utils/font";
import { markInputRule, toggleMark, removeMark } from "tiptap-commands";

export default class FontSize extends Mark {
  get name() {
    return "font_size";
  }

  get defaultOptions() {
    return {
      fonts: [DEFAULT_FONT_SIZE_MAP]
    };
  }

  get schema() {
    return {
      attrs: {
        size: {
          default: ""
        }
      },
      inline: true,
      group: "inline",
      parseDOM: [
        {
          style: "font-size",
          getAttrs: size => (!size.includes("em") ? { size: size } : "")
        }
      ],
      toDOM(node: ProsemirrorNode) {
        const { size } = node.attrs;
        const attrs: { style?: string } = {};

        if (size) {
          attrs.style = `font-size: ${size}`;
        }
        return ["span", attrs, 0];
      }
    };
  }
  commands({ type }) {
    return attrs => {
      if (attrs?.size && attrs?.size != "1em") {
        return toggleMark(type, attrs);
      }
      return removeMark(type);
    };
  }
  inputRules({ type }) {
    return [markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)];
  }
}

// @ts-nocheck
import { Node } from "tiptap";
import {
  toggleBlockType,
  setBlockType,
  textblockTypeInputRule
} from "tiptap-commands";

export default class Heading extends Node {
  get name(): string {
    return "heading";
  }

  get defaultOptions() {
    return {
      aligns: ["left", "center", "right", "justify"],
      levels: [1, 2, 3, 4, 5, 6]
    };
  }

  get schema() {
    return {
      content: "inline*",
      group: "block",
      defining: true,
      draggable: false,
      attrs: {
        align: {
          default: "left"
        },
        level: {
          default: 1
        }
      },
      parseDOM: this.options.levels.map(level => ({
        tag: `h${level}`,
        getAttrs: node => ({
          align: node.style.textAlign || "left",
          level: level
        })
      })),
      toDOM: node => [
        `h${node.attrs.level}`,
        { style: `text-align: ${node.attrs.align}` },
        0
      ]
    };
  }

  public commands({ type, schema }) {
    return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs);
  }

  keys({ type }) {
    return this.options.levels.reduce(
      (items, level) => ({
        ...items,
        ...{
          [`Shift-Ctrl-${level}`]: setBlockType(type, { level })
        }
      }),
      {}
    );
  }

  inputRules({ type }) {
    return this.options.levels.map(level =>
      textblockTypeInputRule(new RegExp(`^(#{1,${level}})\\s$`), type, () => ({
        level
      }))
    );
  }
}
